import * as React from "react"

// External packages
import { StorePostCartsCartReq } from "@medusajs/medusa"
import { useLoadScript } from "@react-google-maps/api"
import {
  getCountries,
  isValidNumber,
  getCountryCallingCode,
  CountryCode,
  parsePhoneNumber,
} from "libphonenumber-js"
import { Box, Flex, Paragraph } from "theme-ui"
import { z } from "zod"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { states } from "../../../utils/us-states"
import { caStates } from "../../../utils/ca-states"

// Services
import { trackCheckoutStepViewed } from "../../../services/analytics"
import Medusa from "../../../services/api"
import {
  trackCheckoutStepCompleted,
  trackNewsletterSubscription,
} from "../../../services/analytics"

// Components
import Form from "../form/Form"
import SubmitButton from "../form/SubmitButton"
import TextField from "../form/TextField"
import CheckboxField from "../form/CheckboxField"
import SelectField from "../form/SelectField"
import CountrySelectField from "../form/CountrySelectField"
import PlacesField from "../form/PlacesField"
import { CountryCodeSelectField } from "../form/CountryCodeSelectField"
import { CheckoutStepAction } from "./Checkout"
import { Button } from "../Button"
import { CollapseOutside } from "../ui/CollapseOutside"

const GOOGLE_PLACES_KEY = process.env.GATSBY_GOOGLE_PLACES_KEY || ""

const deliverySchema = z
  .object({
    email: z.string().email(),
    password: z.string().optional(),
    "first-name": z.string().min(1),
    "last-name": z.string().min(1),
    address_1: z
      .string()
      .min(1, "Enter a valid address")
      .max(35, "Max 35 characters. Enter remaining address in the next field."),
    address_2: z.string().max(35, "Max 35 characters.").optional(),
    "postal-code": z.string().min(1),
    city: z.string().min(1),
    country: z.string().min(1),
    "country-code": z.string().min(1),
    province: z.string().optional(),
    newsletter: z.boolean().optional(),
    phone: z.string(),
  })
  .superRefine((values, ctx) => {
    if (
      (values?.country === "us" || values?.country === "ca") &&
      !values?.province
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Select a state",
        path: ["province"],
      })
    }

    const countryCallingData = countriesCodeSelectData.find(
      (c) => c.value === values["country-code"].toUpperCase()
    )

    if (
      values.phone.length >= countryCallingData.selectedLabel?.length &&
      values.phone.startsWith("+") &&
      !values.phone.startsWith(countryCallingData.selectedLabel ?? "")
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Please use national phone number format",
        path: ["phone"],
      })
    }

    if (
      !isValidNumber(
        values.phone,
        countryCallingData.value ??
          (values.country.toUpperCase() as CountryCode)
      )
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Enter a valid phone number",
        path: ["phone"],
      })
    }

    if (
      values.country === "jp" ||
      values.country === "kr" ||
      values.country === "cn"
    ) {
      const regex = /^[\x00-ʯ]*$/
      const fields = [
        "first-name",
        "last-name",
        "address_1",
        "address_2",
        "city",
        "password",
      ]
      fields.forEach((field) => {
        if (values[field] && !regex.test(values[field])) {
          ctx.addIssue({
            code: "custom",
            message: "Only english letters are allowed",
            path: [field],
          })
        }
      })
    }
  })

export interface Suggestion {
  postal_code?: string
  city?: string
  province?: string
}

export const countriesCodeSelectData = getCountries().map((country) => {
  const getCountryName = new Intl.DisplayNames(["en"], { type: "region" })

  return {
    label: `${getCountryName.of(country)} (+${getCountryCallingCode(country)})`,
    selectedLabel: `+${getCountryCallingCode(country)}`,
    value: country,
  }
})

const DetailsGiftCard: React.FC<CheckoutStepAction> = ({
  previousStep,
  nextStep,
  previousStepLabel,
  nextStepLabel,
  isInEditable,
  isSwap,
}) => {
  const store = useStore()
  const [suggestion, setSuggestion] = React.useState<Suggestion | undefined>()
  const [isAddress2Open, setIsAddress2Open] = React.useState(
    !!store?.cart?.shipping_address?.address_2
  )

  const handleSuggestionChange = (suggestion: Suggestion) => {
    setSuggestion(suggestion)
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_PLACES_KEY,
    libraries: ["places"],
  })

  React.useEffect(() => {
    if (!isSwap) {
      trackCheckoutStepViewed(store?.cart, 1, "email/address")
    }
  }, [])

  const handleOnBlur = (name, value) => {
    if (isInEditable || !name || !value) {
      return
    }

    if (name === "email") {
      return store?.updateCart?.mutate({
        email: value,
      })
    }

    return store?.updateCart?.mutate({
      billing_address: {
        [name]: value,
      },
    })
  }

  return (
    isLoaded && (
      <Form
        schema={deliverySchema}
        onSubmit={async (values) => {
          const callingCodeCountry = countriesCodeSelectData.find(
            (c) => c.value === values["country-code"]?.toUpperCase()
          )?.value

          const update: StorePostCartsCartReq = {
            email: values.email,
            shipping_address: {
              first_name: values["first-name"],
              last_name: values["last-name"],
            },
            billing_address: {
              first_name: values["first-name"],
              last_name: values["last-name"],
              address_1: values.address_1,
              address_2: values.address_2,
              postal_code: values["postal-code"],
              city: values.city,
              phone: parsePhoneNumber(
                values.phone,
                callingCodeCountry ??
                  (values?.country.toUpperCase() as CountryCode)
              ).number,
              province:
                values?.country === "us" || values?.country === "ca"
                  ? values.province
                  : "",
              country_code: values.country,
            },
          }

          const res = await store?.updateCart?.mutateAsync(update)

          if (res?.cart) {
            if (values["newsletter"]) {
              const payload = {
                email: res.cart.email,
                first_name: res.cart.billing_address.first_name,
                last_name: res.cart.billing_address.last_name,
                ids: {},
              }

              await Medusa.newsletter.signup(payload)
              trackNewsletterSubscription(payload)
            }

            trackCheckoutStepCompleted(res.cart, 1)
            nextStep()
          }
        }}
        defaultValues={{
          email: store?.cart?.email ?? undefined,
          "first-name": store?.cart?.billing_address?.first_name ?? undefined,
          "last-name": store?.cart?.billing_address?.last_name ?? undefined,
          address_1: store?.cart?.billing_address?.address_1 ?? undefined,
          address_2: store?.cart?.billing_address?.address_2 ?? undefined,
          "postal-code": store?.cart?.billing_address?.postal_code ?? undefined,
          city: store?.cart?.billing_address?.city ?? undefined,
          country: store?.cart?.billing_address?.country_code ?? undefined,
          "country-code":
            store?.cart?.billing_address?.country_code?.toUpperCase() ??
            undefined,
          phone: store?.cart?.billing_address?.phone
            ? store.cart.billing_address.phone.replace(
                countriesCodeSelectData.find(
                  (c) =>
                    c.value ===
                    store?.cart?.billing_address?.country_code?.toUpperCase()
                )?.selectedLabel ?? "",
                ""
              )
            : undefined,
          province: store?.cart?.billing_address?.province ?? undefined,
        }}
      >
        {({ resetField, setValue }) => (
          <>
            <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
              Billing address
            </Paragraph>
            <TextField
              name="email"
              placeholder="Email"
              type="email"
              hasFloatingLabel
              customErrorMessage="Enter a valid email"
              sx={{ marginBlockEnd: 2 }}
              onBlur={(e) => {
                if (e.target.value) {
                  handleOnBlur("email", e.target.value)
                }
              }}
            />
            <CheckboxField
              name="newsletter"
              label="Sign me up for Tekla news and other digital communications"
              defaultChecked
              sx={{ marginBlockEnd: 6 }}
            />
            <Box sx={{ display: [null, "flex"], gap: 6 }}>
              <TextField
                name="first-name"
                placeholder="First name"
                hasFloatingLabel
                customErrorMessage="Enter a first name"
                sx={{ flex: 1, marginBlockEnd: 6 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("first_name", e.target.value)
                  }
                }}
              />
              <TextField
                name="last-name"
                placeholder="Last name"
                hasFloatingLabel
                customErrorMessage="Enter a last name"
                sx={{ flex: 1, marginBlockEnd: 6 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("last_name", e.target.value)
                  }
                }}
              />
            </Box>
            <Box sx={{ marginBlockEnd: 6 }}>
              <CountrySelectField
                name="country"
                defaultSelectedKey={
                  store?.cart?.billing_address?.country_code ?? undefined
                }
                defaultInputValue={
                  store?.cart?.billing_address?.country?.toString() ?? undefined
                }
                inputProps={{ placeholder: "Country" }}
                hasFloatingLabel
                isGiftCardOnly
                onValueChange={(value, billing_address) => {
                  if (value === billing_address?.country_code) return
                  if (value === "us" || value === "ca") {
                    resetField("province", {
                      defaultValue: billing_address
                        ? billing_address.province
                        : undefined,
                    })
                  }
                  resetField("address_1", {
                    defaultValue: billing_address
                      ? billing_address.address_1
                      : undefined,
                  })
                  resetField("address_2", {
                    defaultValue: billing_address
                      ? billing_address.address_2
                      : undefined,
                  })
                  resetField("postal-code", {
                    defaultValue: billing_address
                      ? billing_address.postal_code
                      : undefined,
                  })
                  resetField("city", {
                    defaultValue: billing_address
                      ? billing_address.city
                      : undefined,
                  })
                  setValue("country-code", value.toUpperCase())
                }}
              />
            </Box>
            {(store?.cart?.billing_address?.country_code === "us" ||
              store?.cart?.billing_address?.country_code === "ca") && (
              <SelectField
                name="province"
                placeholder="State"
                hasFloatingLabel
                data={
                  store?.cart?.billing_address?.country_code === "us"
                    ? states
                    : caStates
                }
                sx={{ marginBlockEnd: 6 }}
              />
            )}
            <Box sx={{ marginBlockEnd: 2 }}>
              <PlacesField
                name="address_1"
                placeholder="Address"
                hasFloatingLabel
                onSuggestionChange={handleSuggestionChange}
                isGiftCardOnly
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("address_1", e.target.value)
                  }
                }}
                setIsAddress2Open={setIsAddress2Open}
              />
            </Box>
            <CollapseOutside
              labelWhenOpened="Show less"
              labelWhenClosed="Add an additional address line (optional)"
              labelPosition="end"
              isOpen={isAddress2Open}
              setIsOpen={setIsAddress2Open}
              sx={{ marginBlockEnd: 6 }}
            >
              <Box sx={{ paddingBlockStart: 4, paddingBlockEnd: 2 }}>
                <TextField
                  name="address_2"
                  placeholder="Address 2"
                  hasFloatingLabel
                />
              </Box>
            </CollapseOutside>
            <Flex sx={{ marginBlockEnd: 6, gap: 6 }}>
              <TextField
                name="postal-code"
                suggestion={suggestion?.postal_code ?? ""}
                placeholder="Postal code"
                hasFloatingLabel
                customErrorMessage="Enter a postal code"
                sx={{ width: "35%" }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("postal_code", e.target.value)
                  }
                }}
              />
              <TextField
                name="city"
                suggestion={suggestion?.city ?? ""}
                placeholder="City"
                hasFloatingLabel
                customErrorMessage="Enter a city"
                sx={{ flex: 1 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("city", e.target.value)
                  }
                }}
              />
            </Flex>
            <Flex sx={{ gap: 6 }}>
              <Box sx={{ width: 30 }}>
                <CountryCodeSelectField
                  name="country-code"
                  inputProps={{ placeholder: "Country code" }}
                  hasFloatingLabel
                  defaultSelectedKey={
                    store?.cart?.billing_address?.country_code?.toUpperCase() ??
                    undefined
                  }
                />
              </Box>
              <TextField
                name="phone"
                placeholder="Phone number"
                type="tel"
                hasFloatingLabel
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("phone", e.target.value)
                  }
                }}
                sx={{ flexGrow: 1 }}
              />
            </Flex>
            <Flex
              sx={{
                flexDirection: ["column", "row"],
                gap: [4, 6],
                marginBlockStart: isInEditable ? 6 : 9,
              }}
            >
              <Button
                onClick={previousStep}
                variant="secondary"
                sx={{ flex: [null, 1], order: [2, "unset"] }}
              >
                {previousStepLabel ? previousStepLabel : "Back to shop"}
              </Button>
              <SubmitButton
                isVisuallyDisabled={store?.updateCart?.isLoading}
                sx={{ flex: [null, 1] }}
              >
                {nextStepLabel ? nextStepLabel : "Next"}
              </SubmitButton>
            </Flex>
          </>
        )}
      </Form>
    )
  )
}

export default DetailsGiftCard
